.paragraph {
    text-align: justify;
    grid-column: 1/3;
}

.service--container {
    position: relative;
    background-color: var(--container-color);
    padding: 1.25rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    margin-top: 40px;
    margin-bottom: 40px;
}

.service--container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.service--content {
    display: grid;
    padding-top: 1rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.service--second--content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* .service--img {
  grid-column: 1;
} */

@media (max-width: 960px) {
    #container--main {
        margin-top: 40px !important;
    }
}
@media (max-width: 700px) {
    .paragraph {
        grid-column: auto;
    }
    .service--content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 568px) {
    .service--content {
        grid-template-columns: repeat(1, 1fr);
    }
    #container--main {
        margin-top: 20px !important;
    }
}
