@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
    --blue: #3273dc;
    --indigo: #6574cd;
    --purple: #b392c7;
    --pink: #e03997;
    --red: #c53030;
    --orange: #d69e2e;
    --yellow: #ebbb32;
    --black: #121212;
    --grey-darkest: #363636;
    --grey-darker: #4a4a4a;
    --grey-dark: #636363;
    --grey: #9e9e9e;
    --grey-light: #b7b7b7;
    --grey-lighter: #c6c6c6;
    --grey-lightest: #e2e2e2;
    --white: #eee;
    --boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --smbBoxShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.06);

    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 250; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #fff;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
    --footer-color: #c1b6fc;

    /*========== Font and typography ==========*/
    --body-font: "Poppins", sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    /*========== Margines Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;

    /*========== widths ==========*/
    --max-width: 1068;
    --header-height: 4rem;
}
/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: 0.875rem;
        --smaller-font-size: 0.813rem;
    }
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
    /* HSL color mode */
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --first-color-lighter: hsl(var(--hue-color), 22%, 35%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
    --footer-color: #13111d;
}
/*========== Button Dark/Light ==========*/
.nav__btns {
    display: flex;
    align-items: center;
}

.change-theme {
    font-size: 1.25rem;
    color: var(--title-color);
    margin-right: var(--mb-1);
    cursor: pointer;
}

.change-theme:hover {
    color: var(--first-color);
}
/*==================== BASE ====================*/

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
}

h1,
h2,
h3,
h4 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

.button:disabled {
    background: silver;
    pointer-events: none;
}
/*==================== REUSABLE CSS CLASSES ====================*/

.section {
    padding: 2rem 0 4rem;
}

.section__title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
    text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
    /* max-width: 768px; */
    /* max-width: 960px; */
    max-width: 1060px;
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.header {
    width: 100%;
    /* position: fixed; */
    /* bottom: 0;
    left: 0;
    position: fixed;
    top: 30px;
    right: 0; */
    z-index: var(--z-fixed);
    /* background-color: var(--body-color); */

    background-color: transparent;
    box-shadow: 0 1px 4px -2px rgba(146, 161, 176, 0.15);
}
/*==================== TOP NAV ====================*/
.top-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--header-height);
    padding: 0 var(--mb-1-5);
    background-color: transparent;
    gap: 0.5rem;
    height: 30px;
    color: var(--title-color);
    /* position: fixed; */
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0; */
}
/* justifyContent: "flex-end",
  position: "fixed",
  zIndex: "1000",
  height: "42px",
  paddingRight: "80px", */
/* btn: {
  borderRadius: "15px",
  color: "#121212",
}, */
/*==================== NAV ====================*/
.nav {
    max-width: var(--max-width);
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: var(--z-fixed);
    /* background-color: var(--body-color); */
    box-shadow: 0 1px 4px -2px rgba(146, 161, 176, 0.15);
}
.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo:hover {
    color: var(--first-color);
}

.nav__logo:hover .slogan {
    color: var(--title-color);
}
.logo {
    height: 45px;
    overflow: visible;
}

.nav__logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
.slogan {
    color: var(--first-color);
}

.nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
}

.nav__toggle:hover {
    color: var(--first-color);
}

/* @media screen and (max-width: 960px) {
    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 0.3s;
    }
} */

.nav__list {
    align-items: center;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.nav__link:hover {
    color: var(--first-color) !important;
}

.nav__icon {
    font-size: 1.2rem;
}

.nav__list {
    display: none;
}
/* .nav__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
} */

/* show menu */
/* .show-menu {
    bottom: 0;
} */
/* Active link */
.active {
    color: var(--first-color) !important;
}

/* Change background header */

/*==================== HOME ====================*/
.home__container {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    object-fit: cover;
    object-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-position: center;
    -moz-background-position: center;
    -o-background-position: center;
    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
}

.home__content {
    position: fixed;
    text-align: center;
}

.home__title {
    font-size: var(--big-font-size);
}

.home__subtitle {
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-75);
}

.home__description {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-2);
}

/*==================== BUTTONS ====================*/
.button {
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    font-weight: var(--font-medium);
}

.button:hover {
    background-color: var(--first-color-alt);
}

.button__icon {
    font-size: 1.25rem;
    margin-left: var(--mb-0-5);
    transition: 0.3s;
}

.button--flex {
    cursor: pointer;
    min-width: 160px;
    display: inline-flex;
    align-items: center;
}

.button--small {
    padding: 0.75rem 1rem;
}

.button--extra--small {
    padding: 0.5rem 1rem;
}

.button--link {
    padding: 0;
    background-color: transparent;
    color: var(--first-color);
}

.button--link:hover {
    background-color: transparent;
    color: var(--first-color-alt);
}
/*==================== ABOUT ====================*/
.about__img {
    width: 100%;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
}

.section__description {
    text-align: center;
}

.why-us__info {
    display: flex;
    justify-content: space-around;
}

.why-us__info-title {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    color: var(--title-color);
}

.why-us__info-name {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.why-us__info-title,
.why-us__info-name {
    display: block;
    text-align: center;
}

.container__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

/*==================== Offering ====================*/
.offering__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 225px;
    min-width: 300px;
}

.offering__data {
    text-align: center;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden;
}

.offering__data:hover > #cube-1 {
    transition: height 0.5s ease-in-out;
    height: 50%;
}

.offering__data:hover > #cube-2 {
    transition: height 0.5s ease-in-out;
    height: 50%;
}

.offering__data-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
}

.offering__data-title-wrapper:hover {
    backdrop-filter: blur(2px);
    transition: backdrop-filter 5s ease-in-out;
}

.offering__data-title-wrapper:hover > * {
    transform: translateY(50px);
    transition: all 0.5s ease-in-out;
}

.offering__data-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
    /* text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: var(--text-color); */

    transform: translateY(0px);
    transition: transform 0.5s ease-in-out;
}

.offering__data-image {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 0%;
    width: 100%;
    transition: height 0.5s ease-in-out;
}

/*==================== Expertise ====================*/
.expertise__info {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem 2rem;
    width: 100%;
    height: 500px;
}

/*==================== Methodology ====================*/
.methodology-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem 2rem;
}

.methodology-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    text-align: center;
}

/*==================== Expertise ====================*/
.support__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem 2rem;
    width: 100%;
    height: 200px;
}

.support__data {
    text-align: center;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.support__data:hover > .support__data-image {
    transition: transform 0.5s ease-in-out;
    transform: scale(1.2);
}

.support__data-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0px);
    padding: 2%;
}

.support__data-title-wrapper:hover {
    backdrop-filter: blur(2px);
    transition: backdrop-filter 5s ease-in-out;
}

.support__data-title-wrapper:hover > .support__data-title {
    transform: translateY(0px);
    transition: all 0.5s ease-in-out;
}

.support__data-title-wrapper:hover > .support__data-subtitle {
    transform: translateY(0px);
    transition: all 0.5s ease-in-out;
    opacity: 1;
    display: inline;
}

.support__data-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    /* color: var(--title-color); */
    color: white;
    transform: translateY(70px);
    transition: transform 0.5s ease-in-out;
}

.support__data-subtitle {
    font-size: var(--normal-font-size);
    /* color: var(--subtitle-color); */
    color: whitesmoke;
    opacity: 0;

    transform: translateY(75px);
    transition: all 0.5s ease-in-out;
}

.support__data-image {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    transform: scale(1);
}

/*==================== FAQ ====================*/
.faq__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.faq__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
}

.faq__card {
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.faq__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    text-align: center;
}

.faq__description {
    font-size: var(--normal-font-size);
    color: var(--text-color-light);
    text-align: center;
}

.faq__icon-wrapper {
    width: 3.5rem;
    height: 3.5rem;
    background-color: var(--input-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2rem var(--first-color-lighter) solid;
}

.faq__icon {
    color: var(--first-color);
    width: 2rem;
    height: 2rem;
}

.faq__bottom-bar {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    border-radius: 30px;
    width: 80%;
    background-color: var(--first-color-lighter);
}

.faq__bottom-bar-text {
    color: var(--text-color);
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--first-color) !important;
}
.swiper-pagination-bullet-active {
    background-color: var(--first-color) !important;
}
/*==================== Swiper ====================*/
.swiper__data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.swiper__img {
    /* width: 64px; */
    height: 64px;
    /* border-radius: 50%; */
}

.swiper__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-2-5);
    text-align: center;
}

/*==================== History ====================*/

.history-info {
    text-align: center;
}

/*==================== History ====================*/

.cta__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5rem;
}

.cta-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.cta-title {
    font-size: var(--h1-font-size);
}

.cta-subtitle {
    font-size: var(--h3-font-size);
}

/*==================== SKILLS ====================*/

.skills__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--mb-2-5);
    cursor: pointer;
}

.skills__icon,
.skills__arrow {
    font-size: 2rem;
    color: var(--first-color);
}

.skills__icon {
    margin-right: var(--mb-0-75);
}

.skills__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.skills__arrow {
    margin-left: auto;
    transition: 0.4s;
}

.skills__list {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.skills__titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--mb-0-5);
}

.skills__unit {
    text-align: center;
}

.skills__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: var(--grey-lightest);
}

.skills__name {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.skills__close .skills__list {
    height: 0;
    overflow: hidden;
}

.skills__open .skills__list {
    height: max-content;
    margin-bottom: var(--mb-2-5);
}

.skills__open .skills__arrow {
    transform: rotate(-180deg);
}

/*==================== SERVICES ====================*/
.services__container {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
}

.services__content {
    position: relative;
    background-color: var(--container-color);
    padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
}

.services__content:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services__icon {
    display: block;
    font-size: 1.5rem;
    color: var(--first-color);
    margin-bottom: var(--mb-1);
}
.services__icon-web {
    display: block;
    font-size: 1.1rem;
    color: var(--first-color);
    margin-bottom: var(--mb-1);
}

.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.services__button {
    cursor: pointer;
    font-size: var(--small-font-size);
}

.services__button:hover .button__icon {
    transform: translateX(0.25rem);
}

.services__modal-content {
    position: relative;
    background-color: var(--container-color);
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.services__modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: var(--first-color);
    cursor: pointer;
}

.dialog__description {
    color: var(--text-color);
}
/*==================== TESTIMONIAL ====================*/
.testimonial__content {
    margin: 0 10%;
}
.testimonial__data,
.testimonial__header {
    display: flex;
}

.testimonial__data {
    justify-content: space-between;
    margin-bottom: var(--mb-1);
}

.testimonial__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: var(--mb-0-75);
}

.testimonial__name {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.testimonial__client {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.testimonial__description {
    margin-bottom: var(--mb-2-5);
}

.testimonial__icon-star {
    color: var(--first-color);
}

/*==================== CONTACT ====================*/
.contact__container {
    gap: 3rem;
}

.contact__information {
    display: flex;
    margin-bottom: var(--mb-2);
}

.contact__icon {
    font-size: 2rem;
    color: var(--first-color);
    margin-right: var(--mb-0-75);
}

.contact__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.contact__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.contact__content {
    background-color: var(--input-color);
    border-radius: 0.5rem;
    padding: 0.75rem 1rem 0.25rem;
}

.contact__label {
    font-size: var(--smaller-font-size);
    color: var(--title-color);
}

.contact__input {
    width: 100%;
    background-color: var(--input-color);
    /* background-color: #eee; */
    color: var(--text-color);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    border: none ;
    outline: none ;
    margin-left:-0.8rem !important;
    border-radius: 10px;
    padding: 0.25rem 0.5rem 0.5rem 0 ;
}

/*==================== FOOTER ====================*/
.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* background-color: rgba(0, 0, 0, 0.25); */
    background-color: var(--footer-color);
}

.footer__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 2rem;
    font-size: var(--normal-font-size);
    margin-top: 2rem;
}

.footer__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-top: var(--mb-0-5);
    margin-bottom: var(--mb-0-5);
}

.footer__links {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: var(--mb-0-5);
}

.footer__link {
    color: var(--title-color) !important;
    cursor: pointer !important;
}

.footer__link:hover {
    color: var(--first-color-lighter);
}

.footer__social {
    font-size: 1.25rem;
    margin-right: var(--mb-1-5);
    height: 24px;
    width: 24px;
}

.footer__social:hover {
    color: var(--first-color-lighter);
    fill: var(--first-color-lighter);
}

.footer__copy {
    font-size: var(--normal-font-size);
    text-align: center;
    color: var(--title-color);
    width: 100%;
    border-top: 1px solid var(--title-color);
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer__link,
.footer__social {
    color: #fff;
    fill: #fff;
}
.footer__socials {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-right: -1.5rem;
}
.footer__copy--link {
    color: var(--first-color-lighter);
}

/*==================== FORM ====================*/
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
    background-color: var(--container-color);
    padding: 1.25rem 1rem 1.5rem 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
}

.form:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.form__img {
    width: 100%;
    height: auto;
}
.form__img--container {
    display: flex;
    align-items: center;
    height: 100vh;
}
.form__title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.form__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-0-25);
}

.form__button {
    border: none;
    font-size: var(--medium-font-size);
    font-weight: var(--font-semi-bold);
}

.login__button {
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: var(--medium-font-size);
    font-weight: var(--font-semi-bold);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.login__img {
    width: 24px;
    height: 24px;
    margin-right: var(--mb-0-25);
}

.facebook__button:hover {
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}
.twitter__button {
    background-color: #00acee;
    color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
}

.twitter__button:hover {
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--grey-lightest);
    margin-bottom: var(--mb-0-25);
}
/*==================== SCROLL UP ====================*/
.scrollup {
    position: fixed;
    right: 5rem;
    bottom: -20%;
    background-color: var(--blue);
    opacity: 0.7;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: 0.4s;
    display: flex;
    align-items: center;
}

.scrollup:hover {
    background-color: var(--first-color-alt);
}

.scrollup__icon {
    font-size: 1.5rem;
    color: #fff;
}

.show-scroll {
    bottom: 5rem;
}

/*==================== SCROLL BAR ====================*/
::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb-color);
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--text-color-light);
}

/*==================== RESPONSIVE ====================*/
@media screen and (max-width: 350px) {
    .support__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem 2rem;
        width: 100%;
        height: 700px;
    }
    .footer__container {
        padding-left: 0rem;
        flex-direction: column;
    }
    .expertise__info {
        display: grid;
        grid-template-columns: auto;
        gap: 2rem 2rem;
        width: 100%;
        height: 1700px;
    }

    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list {
        column-gap: 0;
    }
    .home__content {
        grid-template-columns: 0.25fr 3fr;
    }
    .home__blob {
        width: 180px;
    }
    .qualification__data {
        gap: 0.5rem;
    }
    .skills__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .services__container {
        grid-template-columns: max-content;
        justify-content: center;
    }
    .services__content {
        padding-right: 3.5rem;
    }
    .testimonial__content {
        margin: 0 15%;
    }
    .testimonial__data,
    .testimonial__header {
        flex-direction: column;
        align-items: center;
    }
    .testimonial__img {
        margin-right: 0;
        margin-bottom: var(--mb-0-25);
    }
    .testimonial__data,
    .testimonial__description {
        text-align: center;
    }
    .footer__socials {
        grid-template-columns: repeat(6, 1fr);
    }
    .scrollup {
        right: 2rem;
    }
    .footer__copy {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.7rem;
    }
    .form__img--container {
        height: auto;
    }
}

@media only screen and (max-width: 568px) and (min-width: 350px) {
    .support__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem 2rem;
        width: 100%;
        height: 900px;
    }
    .footer__container {
        padding-left: 0rem;
        flex-direction: column;
    }
    .expertise__info {
        display: grid;
        grid-template-columns: auto;
        gap: 2rem 2rem;
        width: 100%;
        height: 1800px;
    }
    .nav__list {
        column-gap: 0rem;
    }
    .testimonial__content {
        margin: 0 15%;
    }
    .testimonial__data,
    .testimonial__header {
        flex-direction: column;
        align-items: center;
    }
    .testimonial__img {
        margin-right: 0;
        margin-bottom: var(--mb-0-25);
    }
    .testimonial__data,
    .testimonial__description {
        text-align: center;
    }
    .skills__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .scrollup {
        right: 3rem;
    }
    .footer__copy {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .form__img--container {
        height: auto;
    }
}

@media screen and (min-width: 568px) {
    .support__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem 2rem;
        width: 100%;
        height: 900px;
    }
    .footer__container {
        padding-left: 0rem;
        flex-direction: column;
    }
    .expertise__info {
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem 2rem;
        width: 100%;
        height: 900px;
    }
    .home__content {
        grid-template-columns: max-content 1fr 1fr;
    }
    .home__data {
        grid-column: initial;
    }
    .home__img {
        order: 1;
        justify-self: center;
    }
    .about__container,
    .portfolio__content,
    .contact__container,
    .form__container {
        grid-template-columns: repeat(2, 1fr);
    }
    .qualification__section {
        display: grid;
        grid-template-columns: 0.6fr;
        justify-content: center;
    }
    .footer__socials {
        grid-template-columns: repeat(6, 1fr);
    }
    .scrollup {
        right: 3rem;
    }
}

.nav__menu__mobile {
    /* display: flex; */
    /* justify-content: center; */
    margin: 1rem auto;
}
.nav__list__mobile {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
}
.nav__link__mobile {
    column-gap: 0.5rem;
    display: inline-flex;
    align-items: center;
}
.nav__link__mobile:hover {
    color: var(--first-color) !important;
}
.button__navbar__mobile {
    background-color: var(--first-color);
    color: #fff;
    padding: 0.3rem 0.8rem;
    border-radius: 0.4rem;
    transition: 0.2s;
}

.button__navbar__mobile:hover {
    background-color: var(--first-color-alt);
    color: #fff !important;
}

@media screen and (min-width: 960px) {
    .footer__container {
        flex-direction: row;
    }
    .support__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem 2rem;
        width: 100%;
        height: 200px;
    }
    .expertise__info {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 2rem 2rem;
        width: 100%;
        height: 500px;
    }
    .nav__menu__mobile {
        display: none;
    }

    .container {
        margin-left: auto;
        margin-right: auto;
    }
    .logo {
        height: 55px;
        overflow: visible;
    }
    .section {
        padding: 6rem 2rem 2rem;
    }
    .section__subtitle {
        margin-bottom: 4rem;
    }
    .skills__list {
        grid-template-columns: repeat(3, 1fr);
    }
    .skills__container {
        grid-template-columns: repeat(2, 1fr);
    }
    .header {
        top: 0;
        bottom: initial;
    }
    .header,
    .main {
        padding: 0 1rem;
    }
    .nav {
        height: calc(var(--header-height) + 1rem);
        column-gap: 1rem;
    }
    .nav__icon,
    .nav__toggle {
        display: none;
    }
    .nav__list {
        display: flex;
        column-gap: 2rem;
    }
    .nav__menu {
        margin-left: auto;
    }

    .button__navbar {
        display: inline-block;
        background-color: var(--first-color);
        color: #fff;
        padding: 0.4rem 1rem;
        border-radius: 0.5rem;
        transition: 0.2s;
    }

    .button__navbar:hover {
        background-color: var(--first-color-alt);
        color: #fff !important;
    }
    .change-theme {
        margin: 0;
    }
    .home_container {
        row-gap: 5rem;
    }
    .home__content {
        padding-top: 5.5rem;
        column-gap: 2rem;
    }
    .home__blob {
        width: 270px;
    }
    .home__scroll {
        display: block;
    }
    .home__scroll-button {
        margin-left: 3rem;
    }
    .about__container {
        column-gap: 5rem;
    }
    .about-buttons {
        justify-content: initial;
    }
    .services__container {
        grid-template-columns: repeat(3, 218px);
        justify-content: center;
    }
    .services__icon {
        font-size: 2rem;
    }
    .services__icon-web {
        font-size: 1.3rem;
    }
    .services__content {
        padding: 6rem 0 2rem 2.5rem;
    }
    .footer__bg {
        padding: 3rem 0 3.5rem;
    }
    .footer__socials {
        justify-self: flex-end;
        grid-template-columns: repeat(3, 1fr);
    }
    .footer__copy {
        margin-top: 4.5rem;
    }
    .scrollup {
        right: 4rem;
    }
}

@media screen and (min-width: 1024px) {
    .nav {
        margin: 0 10%;
        width: 80%;
    }
    .header,
    .main {
        padding: 0;
    }
    .home__blob {
        width: 320px;
    }
    .home__social {
        transform: translateX(-6rem);
    }
    .skills__list {
        grid-template-columns: repeat(4, 1fr);
    }
    .services__container {
        grid-template-columns: repeat(4, 218px);
    }
    .contact__form {
        width: 460px;
    }
    .contact__inputs {
        grid-template-columns: repeat(2, 1fr);
    }
    .scrollup {
        right: 5rem;
    }
}
